import { GetReport } from '~/services';

import { DOWNLOAD_STATUS } from './constants';

export default class DownloadReport {
  #threadId = 1;

  #setStatusDownload;

  #messageStarted = '';

  constructor(threadId, setStatusDownload, messageStarted = '') {
    this.#threadId = threadId;
    this.#setStatusDownload = setStatusDownload;
    this.#messageStarted = messageStarted;
  }

  async start(filter, refTotalRecords = {}, cancelToken = {}) {
    const data = [];
    let nextPage = 1;
    let scrollId = null;

    do {
      const { data: response } = await GetReport({
        filter,
        page: nextPage,
        scrollId,
        threadId: this.#threadId,
      });

      scrollId = response?.scrollId || null;
      nextPage = response?.nextPage || null;

      if (cancelToken?.current) return;

      const contentSize = response?.data?.length || 0;
      const totalRecords = response?.total || 0;

      this.#setStatusDownload((s) => ({
        ...s,
        message: this.#messageStarted,
        status: DOWNLOAD_STATUS.STARTED,
        total: totalRecords ? (s?.total || 0) + totalRecords : s?.total || 0,
        consumed: (s?.consumed || 0) + (contentSize - 10),
      }));

      data.push(response?.data || []);

      refTotalRecords.current += totalRecords || 0;
    } while (!cancelToken?.current && nextPage != null);

    return data;
  }
}
