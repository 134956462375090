import React, { useEffect, useRef, useState } from 'react';

import { Export, Globe, FileCsv, FileXls, Handshake } from 'phosphor-react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, RadioGroup, SectionSeparator, SelectAsync } from '~/components/atoms';
import { Col, Form, Modal, Row } from '~/components/molecules';

import { useFormHook } from '~/hooks';
import { GetReportFilters, GetCountryReportFilters } from '~/services';

import { FILE_TYPE } from './ModalDownload/constants';
import Filter, { IntervalTypeOptions } from './Filter';
import ModalDownload from './ModalDownload';

function InvoiceReport() {
  const { t } = useTranslation('report');
  const [isOpenModalDownload, setIsOpenModalDownload] = useState(false);
  const [choseItem, setChoseItem] = useState({});
  const [fileType, setFileType] = useState(FILE_TYPE.CSV);
  const [intervalType, setIntervalType] = useState(IntervalTypeOptions.DATE);
  const [fixedCountryOptions, setFixedCountryOptions] = useState([]);
  const { profiles } = useSelector((state) => state.auth);
  const hasCountriesLoaded = useRef(false);

  const REQUIRED_FIELD = `${t('requiredField')}`;

  const schema = Yup.object().shape({
    countries: Yup.array(Yup.string()).min(1).required(REQUIRED_FIELD),
    dealerIds: Yup.array(Yup.number()).required(REQUIRED_FIELD),
    startDate: Yup.string()
      .required(REQUIRED_FIELD)
      .test('validate-maxDate', t('endDateMax'), (value) => {
        const actual = new Date();
        const endDt = new Date(value);

        return endDt <= actual;
      }),
    endDate: Yup.string()
      .required(REQUIRED_FIELD)
      .test('validate-endDate', t('endDateInvalid'), (value, ctx) => {
        const startDt = new Date(ctx.parent.startDate);
        const endDt = new Date(value);

        return endDt > startDt;
      })
      .test('validate-maxDate', t('endDateMax'), (value) => {
        const actual = new Date();
        const endDt = new Date(value);

        return endDt <= actual;
      }),
  });

  const { formFunctions } = useFormHook(schema, {});

  const {
    formState: { errors },
    register,
    getValues,
    setValue,
    setError,
  } = formFunctions;

  const fetchOptionsDealer = (inputValue, callback) => {
    const countries = getValues('countries') || [];

    if (countries.length === 0) {
      toast.info(t('selectCountryEmpty'));
      setError('countries', { type: 'custom', message: t('selectCountryEmpty') });
      return callback([]);
    }

    const params = {
      query: {
        dealerQuery: {
          __args: {
            filters: {
              nameOrDocument: inputValue,
              countries: countries?.map((country) => country?.value || country) || [],
            },
          },
          Dealers: {
            id: true,
            name: true,
            country: true,
          },
        },
      },
    };

    GetReportFilters(params)
      .then(({ data }) => {
        const options =
          data?.dealerQuery?.Dealers?.map(({ id, name }) => ({
            value: id,
            label: name,
          })) || [];

        callback(options);
      })
      .catch((err) => {
        console.error('InvoiceReport:fetchOptionsDealer', { error: err });
        toast.error(t('selectDealerError'));
      });
  };

  const fetchOptionsCountry = (inputValue, callback) => {
    if (fixedCountryOptions?.length) {
      return callback(fixedCountryOptions);
    }

    const params = {
      query: {
        countryQuery: {
          __args: {
            filters: {
              codes: [inputValue.toUpperCase()],
            },
          },
          Countries: {
            code: true,
          },
        },
      },
    };

    GetReportFilters(params)
      .then(({ data }) => {
        const options =
          data?.countryQuery?.Countries?.map(({ code }) => ({
            value: code,
            label: code,
          })) || [];

        callback(options);
      })
      .catch((err) => {
        console.error('InvoiceReport:fetchOptionsCountry', { error: err });
        toast.error(t('selectCountryError'));
      });
  };

  const toggle = () => {
    setIsOpenModalDownload(!isOpenModalDownload);
  };

  const confirm = (item) => {
    setChoseItem(item);
    setIsOpenModalDownload(true);
  };

  // useEffect(() => {
  //   const [MONTH, YEAR] = [new Date().getMonth(), new Date().getFullYear()];
  //
  //   const startDate = new Date(YEAR, MONTH - 1, 1).toISOString().split('T')[0];
  //   const endDate = new Date(YEAR, MONTH, 0).toISOString().split('T')[0];
  //
  //   setValue('startDate', startDate);
  //   setValue('endDate', endDate);
  // }, []);

  useEffect(() => {
    if (hasCountriesLoaded.current === false && profiles?.length > 0) {
      hasCountriesLoaded.current = true;

      const countries = new Set(
        profiles?.reduce((acc, { Countries }) => acc.concat(...Countries), []),
      );

      if (countries.size > 0) {
        GetCountryReportFilters({ subCodes: [...countries] })
          .then(({ data }) => {
            const options =
              data?.countryQuery?.Countries?.map(({ code }) => ({
                value: code,
                label: code,
              })) || [];

            if (options.length === 1) {
              options[0].isFixed = true;
              setValue('countries', [options[0].value]);
            }

            setFixedCountryOptions(options);
          })
          .catch((err) => {
            console.error('InvoiceReport:fetchOptionsCountry', { error: err });
            toast.error(t('selectCountryError'));
          });
      }
    }
  }, [profiles, hasCountriesLoaded]);

  const FILE_TYPES_OPTIONS = [
    { label: FILE_TYPE.CSV, value: FILE_TYPE.CSV },
    { label: FILE_TYPE.XLS, value: FILE_TYPE.XLS },
  ];

  return (
    <>
      <SectionSeparator icon={<Export />} title={t('report')} />
      <Form onSubmit={confirm} schema={schema} formFunctions={formFunctions} withCustomSubmit>
        <Row>
          <Col className="is-6">
            <SelectAsync
              value={fixedCountryOptions?.length === 1 ? fixedCountryOptions : undefined}
              isClearable={fixedCountryOptions.some((v) => !v?.isFixed)}
              cacheOptions
              isMulti
              isSearchable={fixedCountryOptions.length === 0}
              name="countries"
              label={t('country')}
              loadOptions={fetchOptionsCountry}
              defaultOptions={fixedCountryOptions}
              placeholder={t('selectCountryPlaceholder')}
              iconLeft={<Globe color="#009be6" size={22} />}
              error={errors?.countries?.message || ''}
              register={register}
              minChar={3}
              maxChar={3}
            />
          </Col>
          <Col className="is-6">
            <SelectAsync
              isMulti
              name="dealerIds"
              label={t('partner')}
              loadOptions={fetchOptionsDealer}
              placeholder={t('selectDealerPlaceholder')}
              iconLeft={<Handshake color="#009be6" size={22} />}
              error={errors?.dealerIds?.message || ''}
              register={register}
              minChar={3}
              minCharMessage={t('selectMinCharDealerMessage', { minChar: 3 })}
            />
          </Col>
        </Row>
        <Row>
          <Col className="is-6">
            <RadioGroup
              label={t('intervalType')}
              value={intervalType}
              onChange={(value) => setIntervalType(value)}
              options={[{ label: t('date'), value: IntervalTypeOptions.DATE }]}
            />
          </Col>
          <Col className="is-6">
            <RadioGroup
              label={t('fileType')}
              value={fileType}
              onChange={(value) => setFileType(value)}
              options={FILE_TYPES_OPTIONS}
            />
          </Col>
        </Row>
        <Row>
          <Filter variant={intervalType} register={register} errors={errors} />
        </Row>
        <Row>
          <Col className="is-8" />
          <Col className="is-4">
            <Button
              type="submit"
              variant="primary"
              title={t(fileType === FILE_TYPE.CSV ? 'createFileCSV' : 'createFileExcel')}
              iconLeft={fileType === FILE_TYPE.CSV ? <FileCsv size={22} /> : <FileXls size={22} />}
            />
          </Col>
        </Row>
      </Form>
      {isOpenModalDownload && (
        <Modal
          isOpen={isOpenModalDownload}
          toggle={toggle}
          title={t('report')}
          subTitle={t('download')}
          maxWidth="950px"
        >
          <ModalDownload choseItem={choseItem} fileType={fileType} toggle={toggle} />
        </Modal>
      )}
    </>
  );
}

export default InvoiceReport;
